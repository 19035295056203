import React, { PropsWithChildren } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Headers/Header";
import AboutContentV1 from "../components/AboutIntermeta/ContentV/ContentV1/AboutContentV1";

import Background from "../images/Generalsectionbackground.png";
import BackgroundMobile from "../images/Generalsectionbackground-mobile.png";
import { graphql, StaticQuery } from "gatsby";

const query = graphql`{
  allContentfulBlogPost {
    nodes {
      id
      createdAt(formatString: "DD.MM.YYYY")
      title
      thumbnail {
        publicUrl
      }
    }
  }
}`;

interface Post {
  id: string;
  createdAt: string;
  title: string;
  thumbnail: { publicUrl: string; };
}

const Newsroom = () => {
  return (
    <div>
      <Navbar />
      <Header
        title="newsroom"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="bg-white w-[100%] py-32 h-auto overflow-x-hidden">
        <StaticQuery
          query={query}
          render={({ allContentfulBlogPost: { nodes } }) => {
            const sections = splitArrayPer(nodes as Post[], 4);
            return <>
              {sections.map((section, idx) => (
                <Section key={idx} title={idx === 0 ? "All updates" : ""}>
                  {section.map(post => (
                    <div key={post.id} className="max-w-[35vw]">
                      <AboutContentV1
                        IMGSrc={post.thumbnail.publicUrl}
                        Content={post.title}
                        Date={post.createdAt}
                        to={`/posts/${post.id}`}
                      />
                    </div>
                  ))}
                </Section>
              ))}
            </>;
          }}
        />
      </article>
      <Footer />
    </div>
  );
};

export default Newsroom;

const Section: React.FC<PropsWithChildren<{ title?: string; }>> = ({
  children,
  title,
}) => (
  <section className="flex flex-col items-center justify-center">
    <div className="w-[100%] flex items-center justify-center">
      {title && <h2 className="text-black text-5xl font-bold pt-5">{title}</h2>}
    </div>
    <div className="flex lg:flex-row flex-col w-[78%] items-center justify-center">
      {children}
    </div>
  </section>
);

export function splitArrayPer<T>(arr: T[], sliceSize: number): T[][] {
  const numSlices = Math.ceil(arr.length / sliceSize);
  const slices: T[][] = [];
  for (let i = 0; i < numSlices; i++) {
    slices.push(arr.slice(i * sliceSize, (i + 1) * sliceSize));
  }
  return slices;
}